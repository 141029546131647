import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SwitchComponent {
  @Input() isChecked: boolean = false
  @Input() disabled: boolean = false
  @Output() switchChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  updateState() {
    this.isChecked = !this.isChecked
    this.switchChange.emit(this.isChecked)
  }

  rollbackState(): void {
    this.isChecked = !this.isChecked
  }
}
