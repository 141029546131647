export interface EqualityFilter<T> {
  operator: 'equality'
  fieldName: string
  value: T
}

export interface SuperiorityFilter<T> {
  operator: 'superiority'
  fieldName: string
  isStrict: boolean
  value: T
}

export interface InferiorityFilter<T> {
  operator: 'inferiority'
  fieldName: string
  isStrict: boolean
  value: T
}

export interface InFilter<T> {
  operator: 'in'
  fieldName: string
  value: T
}

export interface ContainsFilter<T> {
  operator: 'contains'
  fieldName: string
  value: T
}

export interface OrFilter<T> {
  operator: 'or'
  fieldName: string
  value: T
  type: 'string' | 'number'
  nextSeparator?: string
}

export interface StartsWithFilter<T> {
  operator: 'startswith'
  fieldName: string
  value: T
}

export interface RangeFilter<T> {
  operator: 'range'
  fieldName: string
  min: T
  max: T
}

export interface ExistsFilter {
  operator: 'exists'
  fieldName: string
}

export interface NotExistsFilter {
  operator: 'notexists'
  fieldName: string
}

export type Filter<T = string | number> =
  | EqualityFilter<T>
  | SuperiorityFilter<T>
  | InferiorityFilter<T>
  | ContainsFilter<T>
  | StartsWithFilter<T>
  | RangeFilter<T>
  | InFilter<T>
  | ExistsFilter
  | NotExistsFilter

export class Parameters {
  filters: {
    [key: string]: Filter
  }

  page?: number
  pageLength?: number
  noPagination?: boolean
  sortBy?: string
  sortOrder?: 'ASC' | 'DESC'
  otherParams?: { [key: string]: string | number | boolean }

  constructor() {
    this.filters = {}
  }
}
