import { ReportFilterFormComponent } from '@agroone-app/scene/crop-management/dashboard/components/report-filter-form.component'
import { SignatureComponent } from '@agroone-app/shared/dialog/components/signature.component'
import { ImportService } from '@agroone-app/shared/import/services/import.service'
import { MapComponent } from '@agroone-app/shared/map/components/map.component'
import { CropFilters, FieldFilters } from '@agroone-app/shared/models'
import { ToastService } from '@agroone-front/shared'
import { Crop, Field, Offer, Precision, WorkList } from '@agroone/entities'
import { Injectable } from '@angular/core'
import { AbstractControl, FormGroup } from '@angular/forms'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TranslateService } from '@ngx-translate/core'
import { catchError, Observable, of, switchMap, tap } from 'rxjs'
import { ConfirmComponent } from '../components/confirm.component'
import { CreateNotificationFormComponent } from '../components/create-notification-form.component'
import { CropCloseComponent } from '../components/crop-close.component'
import { CropDataEditComponent } from '../components/crop-data-edit.component'
import { CropFiltersComponent } from '../components/crop-filters.component'
import { CsvUploadComponent } from '../components/csv-upload.component'
import { FieldFiltersFormComponent } from '../components/fields-filter-form.component'
import { FileModeComponent } from '../components/file-mode.component'
import { GrowerCsrFileComponent } from '../components/grower-csr-file.component'
import { InheritFieldComponent } from '../components/inherit-field.component'
import { LocationModeComponent } from '../components/location-mode.component'
import { NoteComponent } from '../components/note.component'
import { OfferComponent } from '../components/offer.component'
import { PictureGalleryComponent } from '../components/picture-gallery.component'
import { PictureComponent } from '../components/picture.component'
import { PlanningDialogComponent } from '../components/planning.component'
import { TechnicianComponent } from '../components/technician.component'
import { WorklistFiltersComponent } from '../components/worklist-filters.component'
import { MapOptions } from '../models/mapOptions.model'
import { WorklistFilters } from '../models/worklist-filters.model'

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class DialogService {
  public STYLE = 'ao-grey'

  constructor(
    private dialog: MatDialog,
    private toastService: ToastService,
    private translateService: TranslateService,
    private importService: ImportService
  ) {}

  public initCsvUpload(style: string): MatDialogRef<CsvUploadComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(CsvUploadComponent, {
      width: '80vw',
      height: '30vh',
      data: { style },
    })

    return dialogRef
  }

  public uploadCsv(entityName: string): Observable<void> {
    const dialogRef = this.initCsvUpload(this.STYLE)
    return dialogRef.afterClosed().pipe(
      switchMap((file: File) => {
        if (!file) {
          return of(null)
        }

        return this.importService.importData(entityName, file).pipe(
          tap(() => {
            this.toastService.showToastSuccess(this.translateService.instant('DIALOG.FILE_MODE.UPLOAD_CSV_SUCCESS'))
          }),
          catchError(() => {
            this.toastService.showToastError(this.translateService.instant('DIALOG.FILE_MODE.UPLOAD_CSV_FAILURE'))
            return of(null)
          })
        )
      })
    )
  }

  public initNote(
    currentNote: AbstractControl,
    style: string,
    readonly: boolean = false,
    allowEmpty: boolean = true
  ): MatDialogRef<NoteComponent> {
    const message = currentNote ? currentNote.value : ''

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(NoteComponent, {
      width: '80vw',
      height: 'auto',
      data: { message, style, readonly, allowEmpty },
    })

    return dialogRef
  }

  public initSignature(style: string, region: string, existingSignature: string): MatDialogRef<SignatureComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(SignatureComponent, {
      width: '90vw',
      height: 'auto',
      data: { style, region, existingSignature },
    })

    return dialogRef
  }

  public initConfirm(message: string, style?: string, title?: string): MatDialogRef<ConfirmComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '80%',
      maxWidth: '70ch',
      data: { message, style, title },
    })

    return dialogRef
  }

  public initPicture(
    style: string,
    files: File[],
    readonly = false,
    readonlyFileNames: string[] = []
  ): MatDialogRef<PictureComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(PictureComponent, {
      width: '80vw',
      height: '80vh',
      data: { style, files, readonly, readonlyFileNames },
    })

    return dialogRef
  }

  public initPicturesGallery(picturesUrl: string[]): MatDialogRef<PictureGalleryComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(PictureGalleryComponent, {
      width: '80vw',
      height: '80vh',
      data: { picturesUrl },
    })

    return dialogRef
  }

  public initLocationMode(precision?: Precision): MatDialogRef<LocationModeComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(LocationModeComponent, {
      width: '80vw',
      height: '30vh',
      data: { precision },
    })

    return dialogRef
  }

  public initFileMode(): MatDialogRef<FileModeComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(FileModeComponent, {
      width: '80vw',
      height: '30vh',
    })

    return dialogRef
  }

  public initMap(mapOptions: MapOptions): MatDialogRef<MapComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(MapComponent, {
      width: '80vw',
      height: '80vh',
      data: mapOptions,
    })

    return dialogRef
  }

  public initPlanning(version: number, date: string): MatDialogRef<PlanningDialogComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(PlanningDialogComponent, {
      width: '80vw',
      height: '30vh',
      data: {
        version,
        date,
      },
    })

    return dialogRef
  }

  public openCropFilters(
    currentFilters: CropFilters,
    crops: Crop[],
    isMobile?: boolean
  ): MatDialogRef<CropFiltersComponent> {
    const modalParams = isMobile
      ? {
          maxWidth: '100vw',
          width: '100vw',
          height: '100vh',
          data: { filters: currentFilters, crops },
          autoFocus: false,
        }
      : {
          height: '80vh',
          data: { filters: currentFilters, crops },
          autoFocus: false,
        }
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(CropFiltersComponent, modalParams)

    return dialogRef
  }

  public initReportFilters(form: FormGroup): MatDialogRef<ReportFilterFormComponent> {
    const dialogRef = this.dialog.open(ReportFilterFormComponent, {
      autoFocus: false,
      data: { form },
    })

    return dialogRef
  }

  public initWorklistFilters(
    currentFilters: WorklistFilters,
    tasks: WorkList[]
  ): MatDialogRef<WorklistFiltersComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(WorklistFiltersComponent, {
      width: '25%',
      data: { filters: currentFilters, tasks },
      autoFocus: false,
    })

    return dialogRef
  }

  public initFieldFilters(currentFilters: FieldFilters, fields: Field[]): MatDialogRef<FieldFiltersFormComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(FieldFiltersFormComponent, {
      data: { filters: currentFilters, fields },
      autoFocus: false,
    })

    return dialogRef
  }

  public initCloseCrop(endDate: Date = new Date()): MatDialogRef<CropCloseComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(CropCloseComponent, {
      data: { endDate },
      autoFocus: false,
    })

    return dialogRef
  }

  public initTechnician(technician: string = null): MatDialogRef<TechnicianComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(TechnicianComponent, {
      data: { technician },
      autoFocus: false,
    })
    return dialogRef
  }

  public initUpdateCropData(
    technician: string = null,
    growingSystem: string = null,
    associatedCrop: string = null
  ): MatDialogRef<CropDataEditComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(CropDataEditComponent, {
      data: { technician, growingSystem, associatedCrop },
      autoFocus: false,
    })
    return dialogRef
  }

  public initInheritField(type: 'edit' | 'create'): MatDialogRef<InheritFieldComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(InheritFieldComponent, {
      width: '80%',
      maxWidth: '70ch',
      data: { type },
    })

    return dialogRef
  }

  public initAddNotifications(description: string, recipientUserId: number) {
    const dialogRef = this.dialog.open(CreateNotificationFormComponent, {
      data: { description, recipientUserId },
    })

    return dialogRef
  }

  public initOffers(cropId: number, offers: Offer[]): MatDialogRef<OfferComponent> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(OfferComponent, {
      width: '80vw',
      data: { cropId, offers },
      autoFocus: false,
    })

    return dialogRef
  }

  public initGrowerCsrFileMode(growerName: string): MatDialogRef<GrowerCsrFileComponent> {
    const dialogRef = this.dialog.open(GrowerCsrFileComponent, {
      width: '70vw',
      height: '70vh',
      data: { growerName },
    })

    return dialogRef
  }
}
