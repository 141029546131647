import { Component, Inject, OnInit } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms'
import { ToastService } from '@agroone-front/shared'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.sass'],
})
export class NoteComponent implements OnInit {
  public form: FormGroup

  public class: string

  get noteContent(): AbstractControl {
    return this.form.get('noteContent')
  }

  get allowEmpty(): boolean {
    return this.data.allowEmpty
  }

  constructor(
    public dialogRef: MatDialogRef<NoteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; style: string; readonly: boolean; allowEmpty: boolean },
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      noteContent: [this.data.message],
    })
    if (this.data.readonly) {
      this.form.get('noteContent').disable()
    }
  }

  save(): void {
    const noteContent: string = this.form.value.noteContent
    if (!this.allowEmpty && !noteContent) {
      this.toastService.showToastWarning(this.translateService.instant('REPORT.PHYTO_REPORTS.EMPTY_NOTE'))
      return
    }
    this.dialogRef.close(this.form.value.noteContent)
  }

  onCancel() {
    this.dialogRef.close(undefined)
  }
}
