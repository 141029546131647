export enum AppRoutingEnum {
  GEOLOCATION = 'geolocation',
  ALERT_NOTIFICATIONS = 'alert-notifications',
  CROP = 'crop',
  CROP_MANAGEMENT = 'crop-management',
  USER_PREFERENCES = 'user-preferences',
  GROWER = 'grower',
  FIELD = 'field',
  DASHBOARD = 'dashboard',
  TPA = 'third-party-applicator',
  RECOMMENDATION = 'recommendation',
  SCOUTING = 'scouting',
  INTERVENTION = 'intervention',
  PLANTING = 'planting',
  MULTI_PESTICIDE_ENTRY = 'multi-pesticide-entry',
  SAMPLE = 'sample',
  LOGOUT = 'logout',
  HISTORY = 'history',
  WORKLIST = 'worklist',
  MANAGE_WORKLIST = 'manage-worklist',
  CSR = 'csr',
}
