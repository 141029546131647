import { ConstantLite, Grower, Technician } from '@agroone/entities'
import { Component, Inject, Injector, OnInit } from '@angular/core'
import { AbstractControl, FormGroup } from '@angular/forms'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { BaseComponent } from '@agroone-app/core/base.component'
import { GrowerService } from '@agroone-app/shared/grower/services/grower.service'

import { ReportTabs } from '../enums/report-tabs'
import { ReportService } from '../services/report.service'
import { FormElement } from '@agroone-app/shared/form/models/form-element.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SharedConstantService, SharedUserService } from '@agroone-front/shared'
import { UserManagementService } from '@agroone-app/core/user/user-management.service'

@UntilDestroy()
@Component({
  selector: 'app-report-filter-form',
  templateUrl: './report-filter-form.component.html',
  styleUrls: ['./report-filter-form.component.sass'],
})
export class ReportFilterFormComponent extends BaseComponent implements OnInit {
  public form: FormGroup
  public growers: Grower[]
  public cropTypes: ConstantLite[] = []
  public technicians: Technician[]
  public selectedReport: string
  public weeklySowing = ReportTabs.weeklySowing
  public plantedDateElement: FormElement

  get cropType(): AbstractControl {
    return this.form.get('cropType')
  }

  get grower(): AbstractControl {
    return this.form.get('grower')
  }

  get plantedDateRange(): AbstractControl {
    return this.form.get('plantedDateRange')
  }

  get technician(): AbstractControl {
    return this.form.get('technician')
  }

  constructor(
    public override userService: SharedUserService,
    private dialogRef: MatDialogRef<ReportFilterFormComponent>,
    private constantService: SharedConstantService,
    private growerService: GrowerService,
    private reportService: ReportService,
    private _userManagementService: UserManagementService,
    readonly injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: { form: FormGroup }
  ) {
    super(injector)
  }

  ngOnInit() {
    this.form = this.data.form
    this._loadCropTypes()
    this._loadTechnicians()
    this._loadGrowers()
  }

  save() {
    this.dialogRef.close(this.form.getRawValue())
  }

  public technicianDisplay(technician?: Technician): string | undefined {
    return technician ? `${technician.firstname} ${technician.lastname}` : undefined
  }

  public filterTechnician(value: Technician, input: Technician | string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string =
      input instanceof Object ? `${input.firstname} ${input.lastname}`?.toLowerCase() : input.toLowerCase()
    return `${value.firstname} ${value.lastname}`.toLowerCase().indexOf(filterValue) !== -1
  }

  public sortTechnician(a: Technician, b: Technician): number {
    return `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`)
  }

  public growerDisplay(grower?: Grower): string | undefined {
    return grower ? grower.growerName : undefined
  }

  public filterGrower(value: Grower, input: Grower | string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string = input instanceof Object ? input.growerName?.toLowerCase() : input.toLowerCase()
    return value.growerName.toLowerCase().indexOf(filterValue) !== -1
  }

  public _loadCropTypes() {
    this.cropTypes = this.constantService?.croptypes.filter(
      (cropType) =>
        cropType.regions &&
        cropType.regions.some((r) => r.name === this.userService.currentUser.regionName || r.name === 'ALL')
    )
  }

  private _loadGrowers() {
    this.growerService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((growers: Grower[]) => {
        if (growers.length > 0) {
          this.growers = growers.sort((x, y) => x.growerName.localeCompare(y.growerName))
        } else {
          this.growers = []
        }
      })
  }

  private _loadTechnicians() {
    this._userManagementService
      .getTechnicians()
      .pipe(untilDestroyed(this))
      .subscribe((technicians: Technician[]) => {
        this.technicians = technicians
      })
  }
}
