import { Injectable } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { Parameters, SharedUserService } from '@agroone-front/shared'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import format from 'date-fns/format'
import { ReportTabs } from '../enums/report-tabs'
import { DateFormats } from '@agroone/dates'

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private onSubmitFilter = new Subject<void>()
  private onSubmitSearchBar: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public filterSearchObs = this.onSubmitFilter.asObservable()
  public searchBarObs: Observable<string> = this.onSubmitSearchBar.asObservable()
  private _form: FormGroup = new FormGroup({
    compositeSearch: new FormControl(),
    cropType: new FormControl(),
    grower: new FormControl(),
    plantedDateRange: new FormControl(),
    technician: new FormControl(),
  })

  constructor(private userService: SharedUserService) {}

  getFormGroup(): FormGroup {
    return this._form
  }

  getSearchBarValue(): string {
    return this.onSubmitSearchBar.getValue()
  }

  getQueryParams(tabSelected?: ReportTabs): Parameters {
    const params: Parameters = {
      filters: {
        region: {
          fieldName: 'region',
          operator: 'equality',
          value: this.userService.currentUser.regionName,
        },
      },
      noPagination: true,
    }

    if (this._form?.get('grower')?.value) {
      params.filters.growerId = {
        fieldName: 'growerId',
        operator: 'equality',
        value: this._form?.get('grower')?.value?.id,
      }
    }

    if (this._form?.get('cropType')?.value) {
      params.filters.cropTypeId = {
        fieldName: 'cropTypeId',
        operator: 'equality',
        value: this._form.get('cropType')?.value?.croptypeId,
      }
    }

    if (this._form.get('technician')?.value && !this.userService.currentUser?.limitDisplay) {
      params.filters.technician = {
        fieldName: 'technician',
        operator: 'equality',
        value: this._form.get('technician').value?.email,
      }
    }

    if (this.userService.currentUser?.limitDisplay) {
      params.filters.technician = {
        fieldName: 'technician',
        operator: 'equality',
        value: this.userService.currentUser?.email,
      }
    }

    if (tabSelected !== ReportTabs.missingInfos) {
      if (this._form.get('plantedDateRange')?.value?.length) {
        const dateRange = this._form.get('plantedDateRange')?.value
        const startAt = dateRange ? format(new Date(dateRange[0]), DateFormats.YYYY_MM_DD) : ''
        const endAt = dateRange ? format(new Date(dateRange[1]), DateFormats.YYYY_MM_DD) : ''
        params.filters.plantedDate = {
          fieldName: 'plantedDate',
          operator: 'range',
          min: encodeURIComponent(startAt),
          max: encodeURIComponent(endAt),
        }
      }
    }

    return params
  }

  sendSubmitForm() {
    this.onSubmitFilter.next()
  }

  sendSubmitSearchbar(value: string) {
    this.onSubmitSearchBar.next(value)
  }
}
