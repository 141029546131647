import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FilterPipe } from './pipes/filter.pipe'
import { KeyboardDirective } from './directives/keyboard.directive'
import { DatetimepickerComponent } from './components/datetime-picker/datetimepicker.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatIconModule } from '@angular/material/icon'
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { TranslateModule } from '@ngx-translate/core'
import { AutocompleteSelectComponent } from './components/autocomplete-select.component'
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { CustomDateAdapter } from './CustomDateAdapter'
import { ResumeItemComponent } from './components/resume-item.component'
import { YearPickerComponent } from './components/year-picker/year-picker.component'
import { JoinPipe } from './pipes/join.pipe'
import { OptionalPipe } from './pipes/optional.pipe'
import { UiScrollModule } from 'ngx-ui-scroll'
import { Platform } from '@angular/cdk/platform'
import { CalendarModule } from 'primeng/calendar'
import { InputNumberDirective } from './directives/input-number.directive'
import { SharedUserService, SortModule } from '@agroone-front/shared'

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    SortModule,
    UiScrollModule,
    CalendarModule,
    FormsModule,
  ],
  declarations: [
    FilterPipe,
    KeyboardDirective,
    InputNumberDirective,
    DatetimepickerComponent,
    AutocompleteSelectComponent,
    ResumeItemComponent,
    YearPickerComponent,
    JoinPipe,
    OptionalPipe,
  ],
  exports: [
    FilterPipe,
    KeyboardDirective,
    InputNumberDirective,
    DatetimepickerComponent,
    AutocompleteSelectComponent,
    ResumeItemComponent,
    YearPickerComponent,
    JoinPipe,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform, SharedUserService],
    },
  ],
})
export class FormModule {}
