import 'reflect-metadata'
import { Languages } from '../enums/languages.enum'
import { BusinessUnits } from '../enums/business-units.enum'
import { AreaUnit } from '../enums/area-unit.enum'
import { CostUnit } from '../enums/cost-unit.enum'
export class Region {
  id: number | undefined
  name: string | undefined
  shortName: string | undefined
  defaultYieldUnit: string | undefined
  defaultAreaUnit: AreaUnit | undefined
  defaultTemperatureUnit: string
  businessUnit: BusinessUnits | undefined
  isFresh: boolean | undefined
  isLongLife: boolean | undefined
  defaultLengthUnit: string
  defaultWeightUnit: string
  defaultSpeedUnit: string
  defaultCadenceUnit: string
  defaultWindSpeedUnit: string
  defaultSeedingRateUnit?: string
  phytoCertification: string
  businessUnitAddress: string
  defaultLatitude: number
  defaultLongitude: number
  countryId?: number
  country?: string
  defaultLanguage: Languages
  defaultCostUnit: CostUnit

  constructor(region?: Region) {
    this.id = region?.id
    this.name = region?.name
    this.shortName = region?.shortName
    this.defaultYieldUnit = region?.defaultYieldUnit
    this.defaultAreaUnit = region?.defaultAreaUnit
    this.defaultTemperatureUnit = region?.defaultTemperatureUnit ?? ''
    this.businessUnit = region?.businessUnit
    this.isFresh = region?.isFresh
    this.isLongLife = region?.isLongLife
    this.defaultLengthUnit = region?.defaultLengthUnit ?? ''
    this.defaultWeightUnit = region?.defaultWeightUnit ?? ''
    this.defaultSpeedUnit = region?.defaultSpeedUnit ?? ''
    this.defaultCadenceUnit = region?.defaultCadenceUnit ?? ''
    this.phytoCertification = region?.phytoCertification ?? ''
    this.businessUnitAddress = region?.businessUnitAddress ?? ''
    this.defaultWindSpeedUnit = region?.defaultWindSpeedUnit ?? ''
    this.defaultSeedingRateUnit = region?.defaultSeedingRateUnit ?? ''
    this.countryId = region?.countryId
    this.country = region?.country
    this.defaultLatitude = region?.defaultLatitude
    this.defaultLongitude = region?.defaultLongitude
    this.defaultLanguage = region?.defaultLanguage ?? Languages['en-us']
    this.defaultCostUnit = region?.defaultCostUnit
  }
}

export class RegionDto {
  id?: number
  name: string | undefined
  shortName: string | undefined
  defaultYieldUnit: string | undefined
  defaultAreaUnit: AreaUnit | undefined
  defaultTemperatureUnit?: string | undefined
  businessUnit: string | undefined
  isFresh: number | undefined
  isLongLife: number | undefined
  defaultLengthUnit?: string
  defaultWeightUnit?: string
  defaultSpeedUnit?: string
  defaultCadenceUnit?: string
  defaultWindSpeedUnit?: string
  defaultSeedingRateUnit?: string
  phytoCertification?: string
  businessUnitAddress?: string
  countryId?: number
  country?: string
  defaultLanguageId?: number
  defaultLanguage?: string
  defaultCostUnit: CostUnit

  constructor(region?: RegionDto) {
    this.id = region?.id
    this.name = region?.name
    this.shortName = region?.shortName
    this.defaultYieldUnit = region?.defaultYieldUnit
    this.defaultAreaUnit = region?.defaultAreaUnit
    this.defaultTemperatureUnit = region?.defaultTemperatureUnit
    this.businessUnit = region?.businessUnit
    this.isFresh = region?.isFresh
    this.isLongLife = region?.isLongLife
    this.defaultLengthUnit = region?.defaultLengthUnit
    this.defaultWeightUnit = region?.defaultWeightUnit
    this.defaultSpeedUnit = region?.defaultSpeedUnit
    this.defaultCadenceUnit = region?.defaultCadenceUnit
    this.phytoCertification = region?.phytoCertification
    this.businessUnitAddress = region?.businessUnitAddress
    this.defaultWindSpeedUnit = region?.defaultWindSpeedUnit
    this.defaultSeedingRateUnit = region?.defaultSeedingRateUnit
    this.countryId = region?.countryId
    this.country = region?.country
    this.defaultLanguageId = region?.defaultLanguageId ?? 1
    this.defaultLanguage = region?.defaultLanguage ?? Languages['en-us']
    this.defaultCostUnit = region?.defaultCostUnit
  }
}
